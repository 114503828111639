<template>
  <div id="header">
    <div id="header_body">
      <Message />
      <div class="topbar">
        <router-link :to="`/${$i18n.locale}/`" class="navbar-brand-phone"
          ><img src="../assets/logo-ok.png" class="logo" />
          <img src="../assets/ santa.png" class="santa" />
        </router-link>
        <!-- <div class="none-phone">
        <router-link :to="`/${$i18n.locale}/contacts`"  class="none-phone_contact">{{$t('nav.contacts')}}</router-link>
      </div> -->
        <div class="container block-phone">
          <div class="container-content">
            <div class="container-content-col phone-numbers">
              <a href="tel:+996776608010" class="phone-number"
                ><i class="fa-brands fa-whatsapp"></i> +996 706 60 80 10</a
              >
              <a href="tel:+996556608010" class="phone-number"
                ><i class="fa-brands fa-whatsapp"></i> +996 556 60 80 10</a
              >
              <span class="phone-number"
                ><i class="fa-solid fa-phone"></i> +996 559 60 80 10</span
              >
            </div>
            <div>
              <span class="work-time" style="float: left">
                {{ $t("nav.work_schedule") }} 9:00 - 18:00</span
              >
            </div>
          </div>
        </div>
      </div>
      <nav class="navbar navbar-expand-lg bg-light">
        <div class="container-fluid">
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class="collapse navbar-collapse header-menu"
            id="navbarTogglerDemo01"
          >
            <router-link :to="`/${$i18n.locale}/`" class="navbar-brand"
              ><img src="../assets/logo-ok.png" class="logo" />
              <img src="../assets/ santa.png" class="santone" />
            </router-link>
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link
                  :to="`/${$i18n.locale}/open-data`"
                  class="nav-link"
                  >{{ $t("nav.open_data") }}</router-link
                >
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ $t("nav.about") }}
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link
                      :to="`/${$i18n.locale}/news`"
                      class="dropdown-item"
                      >{{ $t("nav.news") }}</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link
                      :to="`/${$i18n.locale}/contacts`"
                      class="dropdown-item"
                      >{{ $t("nav.contacts") }}</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="`/${$i18n.locale}/about`"
                      class="dropdown-item"
                      >{{ $t("nav.about") }}</router-link
                    >
                  </li>
                  <!-- <li>
                    <router-link
                      :to="`/${$i18n.locale}/lending-about`"
                      class="dropdown-item"
                      >{{ $t("nav.ais") }}</router-link
                    >
                  </li> -->
                </ul>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ $t("nav.school_codes") }}
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <router-link
                      :to="`/${$i18n.locale}/bishkek-school-codes`"
                      class="dropdown-item"
                      >Бишкек</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="`/${$i18n.locale}/osh-city-school-codes`"
                      class="dropdown-item"
                      >город Ош</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="`/${$i18n.locale}/batken-school-codes`"
                      class="dropdown-item"
                      >Баткен</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="`/${$i18n.locale}/zhalal-abad-school-codes`"
                      class="dropdown-item"
                      >ДжалалАбад</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="`/${$i18n.locale}/naryn-school-codes`"
                      class="dropdown-item"
                      >Нарын</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="`/${$i18n.locale}/osh-school-codes`"
                      class="dropdown-item"
                      >Ош</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="`/${$i18n.locale}/talas-school-codes`"
                      class="dropdown-item"
                      >Талас</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="`/${$i18n.locale}/chui-school-codes`"
                      class="dropdown-item"
                      >Чуй</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="`/${$i18n.locale}/ik-school-codes`"
                      class="dropdown-item"
                      >Иссык куль</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <router-link
                  :to="`/${$i18n.locale}/rent-payment`"
                  class="nav-link"
                  >{{ $t("nav.rent_payment") }}</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  :to="`/${$i18n.locale}/questions-and-answers`"
                  class="nav-link"
                  >{{ $t("nav.questions") }}</router-link
                >
              </li>
              <!-- <li class="nav-item">
                <router-link :to="`/${$i18n.locale}/pisa`" class="nav-link"
                  >PISA</router-link
                >
              </li> -->
              <li class="nav-item">
                <router-link
                  :to="`/${$i18n.locale}/regulations`"
                  class="nav-link"
                  >{{ $t("nav.regulations") }}</router-link
                >
              </li>
              <!-- <li class="nav-item">
                <router-link
                  :to="`/${$i18n.locale}/libraries-info`"
                  class="nav-link"
                >
                  {{$t('nav.libraries-info')}} 
                  Блог
                </router-link>
              </li> -->
              <li class="nav-item">
                <div class="d-flex justify-content-end py-1">
                  <select
                    id="lang_select"
                    class="form-select w-100 me-2"
                    aria-label="Default select example"
                    v-model="$i18n.locale"
                  >
                    <option
                      selected
                      v-for="(lang, i) in langs"
                      :key="`Lang${i}`"
                      :value="lang"
                    >
                      {{ lang == "kg" ? "кыргызча" : "русский" }}
                    </option>
                  </select>
                  <!-- <div class="social-icons">
                    <a
                      href="https://www.instagram.com/jany_kitep/?igshid=YmMyMTA2M2Y%3D"
                      class="fa-brands fa-instagram"
                    ></a>
                    <a
                      href="https://www.facebook.com/Janykitep/"
                      class="fa-brands fa-facebook"
                    ></a>
                    <a
                      href="https://t.me/+ijWta5DohU5iN2My"
                      class="fa-brands fa-telegram"
                    ></a>
                  </div> -->
                </div>
              </li>
            </ul>
          </div>
          <a
            target="_blank"
            href="https://kabinet.okuukitebi.edu.kg/sign-in"
            class="btn primary-button btn-sign"
            type="submit"
          >
            {{ $t("banner.sign_in_btn") }}
          </a>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import Message from "@/components/Message.vue";

export default {
  components: {
    Message,
  },
  data() {
    return {
      langs: ["kg", "ru"],
      scrolling: false,
    };
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      console.log("locale", locale);
      this.$router.push({
        params: { lang: locale },
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.scrolling = window.pageYOffset > 100;
    });
  },
};
</script>

<style scoped>
.container-fluid .btn-sign {
  display: none;
}

.btn-book:hover,
.btn-sign:hover {
  background-color: #0088e1 !important;
}
#header {
  margin-top: 144.2px;
  z-index: 1000;
}
#header_body {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
  position: fixed; /* Фиксировать navbar сверху */
  top: 0; /* Позиционировать navbar по верхнему краю */
  width: 100%; /* Занимать всю ширину экрана */
  /* background-color: rgba(0, 0, 0, 0.8); */
  transition: background-color 200ms ease;
  z-index: 1000;
}

#header_body.scroll {
  background-color: #0088e1;
}
#header_body.scroll * a {
  color: white;
}

/* .none-phone {
  display: none;
} */
.container-content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 50px;
  width: 100%;
}

.container-content-col {
  display: flex;
  justify-content: space-evenly;
  width: 60%;
}
.form-select {
  border: none;
  background-color: #f8f9fa00 !important;
}
.form-select:focus {
  outline: none !important;
}
a:hover {
  color: #fff;
}
.logo {
  width: 80px;
}
.santone {
  width: 90px;
  position: absolute;
  bottom: 35px;
  left: 20px;
  transform: rotate(15deg); /* Наклоняем элемент */

}

.santa {
  position: absolute;
  bottom: 34px;
  left: 15px;
  transform: rotate(15deg); /* Наклоняем элемент */
}

.navbar-nav {
  display: block;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.nav-link {
  font-size: 18px;
  font-weight: 400 !important;
  color: #333 !important;
}
.nav-link:hover {
  color: #0088e1 !important;
}
.fa-instagram {
  margin-top: 4px;
  font-size: 19px;
  text-decoration: none;
}
.fa-facebook {
  text-decoration: none;
  margin-top: 4px;
  font-size: 19px;
  margin-left: 4px;
}
.fa-telegram {
  margin-top: 4px;
  font-size: 19px;
  margin-left: 4px;
  text-decoration: none;
}
.fa-brands:hover {
  color: #0088e1;
}
.fa-whatsapp {
  color: #fff;
}
.fa-whatsapp:hover {
  color: #fff;
}
.fa-phone {
  color: #fff;
}
.social-icons {
  display: flex;
  justify-content: space-between;
  justify-items: center;
}
.topbar {
  background-color: #0088e1;
  height: 50px;
  display: block;
  margin: 0 auto;
  /* padding-left: 20rem; */
}
.phone-number {
  color: #fff;
  text-decoration: none !important;
}
.phone-number:hover {
  color: #fff !important;
}

.work-time {
  color: #fff;
  margin-left: 20px;
}
.navbar-brand-phone {
  display: none;
}
@media (min-width: 850px) and (max-width: 1120px) {
  .nav-link {
    font-size: 15px;
  }
}
@media (max-width: 990px) {
  #header {
    margin-top: 100px;
  }
  .container-fluid .btn {
    display: block;
    font-size: 12px;
  }
  .btn-book {
    width: 30%;
  }

  .santone{
    display: none;
  }
}

@media (min-width: 770px) and (max-width: 1120px) {
  .container-content-col {
    width: 90%;
  }
  .block-phone span {
    font-size: 13px;
  }

  .block-phone a {
    font-size: 13px;
  }

  .work-time {
    margin-left: 5px;
    width: 150px;
  }
}
@media (max-width: 800px) {
  .message {
    display: none;
  }
}

@media (max-width: 768px) {
  .block-phone {
    display: none;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .topbar {
    background-color: #0088e1;
    height: 50px;
    display: block;
    width: 100%;
    font-size: smaller;
    display: flex;
    justify-content: center;
    /* margin: 0 auto; */
    /* padding-left: 20rem; */
  }
  .navbar-brand {
    display: none;
  }

  .navbar-brand-phone {
    position: absolute;
    z-index: +1;
    display: block;
    background-color: white;
    width: 70px;
    height: 80px;
    border-radius: 0 0 7px 7px;
    box-shadow: 1px 1px 2px 3px #25252558;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navbar-brand-phone > img {
    width: 60px;
  }
}
@media (max-width: 320px) {
  .container-fluid .btn {
    display: block;
    font-size: 10px;
    font-weight: 500;
  }
}
@media only screen and (min-width: 1030px) and (max-width: 1366px) {
  .topbar {
    background-color: #0088e1;
    height: 50px;
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  /* .rented-data {
    position: absolute;
    left: 123px!important;
    width: 686px!important;
} */
}
</style>
